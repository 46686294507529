import { graphql } from "gatsby";
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"


const Privacy = ({data}) => {
  const pageContent = data.allWpPage.nodes[0];
  return(
  <Layout>
    <Seo title={pageContent?.seo.title} image="https://dev.alkye.com/wp-content/uploads/2021/12/Sonia-1-1-scaled-1.webp" description={pageContent?.seo.metaDesc} focuskw={pageContent?.seo.focuskw} />
    
    <div className="page-intro pb-3 pb-xl-4 page-inner">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-xl-9 col-xxl-10" data-aos="fade-up">
          <div className="subtitle mb-2 text-uppercase h5">IMPORTANT INFORMATION</div>
            <h1 className="title mb-3 mb-md-4 pb-xl-2" dangerouslySetInnerHTML={{__html: pageContent?.title}} />
          </div>
        </div>
      </div>
    </div>


    <div className="container py-120 pt-0 mb-md-5 mb-4" dangerouslySetInnerHTML={{__html: pageContent?.content}} />

    
  
  </Layout>
 )
}

export default Privacy

export const query = graphql`{
  allWpPage(filter: {slug: {eq: "privacy-policy"}}) {
    nodes {
      seo {
        title
        metaDesc
        focuskw
      }
      title
      content
    }
  }
}`